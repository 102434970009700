import {
  Checkout,
  ItemTypeItemType,
  LineItem,
} from '@wix/ambassador-ecom-v1-checkout/types';

export const hasGiftCardItem = (checkout: Checkout): boolean =>
  checkout.lineItems!.some(
    (item: LineItem) =>
      item.itemType?.preset === ItemTypeItemType.GIFT_CARD ||
      item.subscriptionOptionInfo,
  );
