import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';

export const createAmountsFormatter = ({
  flowAPI,
  currency,
}: {
  flowAPI: ControllerFlowAPI;
  currency: string;
}) => {
  const formatCurrency = flowAPI.getCurrencyFormatter();

  return ({
    redeemableAmount,
    balanceAmount,
  }: {
    redeemableAmount: number;
    balanceAmount: number;
  }) => ({
    redeemableStoreCredit: formatCurrency({
      value: redeemableAmount.toFixed(2),
      currency,
    }),
    userTotalStoreCredit: formatCurrency({
      value: balanceAmount.toFixed(2),
      currency,
    }),
  });
};
