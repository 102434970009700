import { Checkout } from '@wix/ambassador-ecom-v1-checkout/types';
import { GetWalletDetailsResponse } from '@wix/ambassador-rise-integration-v1-wix-integration-provider/types';

export function shouldRenderWidget({
  wallet,
  checkout,
}: {
  wallet: GetWalletDetailsResponse;
  checkout: Checkout;
}) {
  return (
    wallet.giftCardInfo?.currency === checkout.currency &&
    parseFloat(wallet.giftCardInfo?.balance!) > 0
  );
}
