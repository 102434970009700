import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { getCheckout as getCheckoutHttp } from '@wix/ambassador-ecom-v1-checkout/http';
import { GetCheckoutResponse } from '@wix/ambassador-ecom-v1-checkout/types';
import { Transactions } from '../monitoring/transactions';

export interface GetCheckoutInfoArgs {
  checkoutId: string;
}
export class GetCheckoutApi {
  private readonly flowAPI: ControllerFlowAPI;

  constructor({ flowAPI }: { flowAPI: ControllerFlowAPI }) {
    this.flowAPI = flowAPI;
  }

  async getCheckout({
    checkoutId,
  }: GetCheckoutInfoArgs): Promise<GetCheckoutResponse> {
    this.flowAPI.fedops.interactionStarted(Transactions.GetCheckout);
    const response = await this.flowAPI.httpClient.request(
      getCheckoutHttp({
        id: checkoutId,
      }),
    );
    this.flowAPI.fedops.interactionEnded(Transactions.GetCheckout);

    return response.data;
  }
}
