import { GiftCard } from '@wix/ambassador-ecom-v1-checkout/types';
import { ApplicationStatus } from '../types/types';

export function shouldDisableWidget({
  total,
  giftCard,
}: {
  total: string;
  giftCard: GiftCard;
}): ApplicationStatus {
  if (giftCard || parseFloat(total) === 0) {
    return ApplicationStatus.Disabled;
  }
  return ApplicationStatus.IDLE;
}
